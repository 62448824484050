// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-track-record-js": () => import("./../../../src/pages/track-record.js" /* webpackChunkName: "component---src-pages-track-record-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/[singlePost].js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

